<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    largeMedia: VoixMediaFieldInterface
    largeTitle: VoixTextFieldInterface
    largeLink: VoixLinkFieldInterface
    topMedia: VoixMediaFieldInterface
    topTitle: VoixTextFieldInterface
    topLink: VoixLinkFieldInterface
    bottomMedia: VoixMediaFieldInterface
    bottomTitle: VoixTextFieldInterface
    bottomLink: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Promo Grid', group: 'Kimpton', layouts: ['Kimpton'] },
  description: 'Large image card flanking two smaller image cards.',
  preview: 'SlicesKimptonPromoGrid.jpg',
  fields: {
    largeMedia: {
      type: 'media',
      label: 'Large Media',
      breakpoints: {
        default: {
          width: 720,
          height: 560,
        },
      },
    },

    largeTitle: {
      type: 'text',
      label: 'Large Title',
    },

    largeLink: {
      type: 'link',
      label: 'Large Link',
      enabled: true,
    },

    topMedia: {
      type: 'media',
      label: 'Top Small Media',
      breakpoints: {
        default: {
          width: 720,
          height: 560,
        },
      },
    },

    topTitle: {
      type: 'text',
      label: 'Top Small Title',
    },

    topLink: {
      type: 'link',
      label: 'Top Small Link',
      enabled: true,
    },

    bottomMedia: {
      type: 'media',
      label: 'Bottom Small Media',
      breakpoints: {
        default: {
          width: 720,
          height: 560,
        },
      },
    },

    bottomTitle: {
      type: 'text',
      label: 'Bottom Small Title',
    },

    bottomLink: {
      type: 'link',
      label: 'Bottom Small Link',
      enabled: true,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      largeMedia: {
        value: 'https://source.unsplash.com/random/340x210',
      },
      largeTitle: {
        value: 'Title',
      },

      largeLink: {
        value: {
          link_type: 'Web',
          url: '#',
        },
      },

      topMedia: {
        value: 'https://source.unsplash.com/random/340x210',
      },
      topTitle: {
        value: 'Title',
      },

      topLink: {
        value: {
          link_type: 'Web',
          url: '#',
        },
      },

      bottomMedia: {
        value: 'https://source.unsplash.com/random/340x210',
      },
      bottomTitle: {
        value: 'Title',
      },

      bottomLink: {
        value: {
          link_type: 'Web',
          url: '#',
        },
      },
    },
  }],
})

function goToLink(field: VoixLinkFieldInterface) {
  if (field.enabled && field.value) {
    window.location = field.value.href
  }
}
</script>

<template>
  <div>
    <div class="container mx-auto grid lg:grid-cols-12 gap-16">
      <div
        class="relative lg:col-span-8 aspect-[4/3] text-white flex items-end justify-center"
        :class="{
          'cursor-pointer': fields.largeLink.enabled }
        " @click="goToLink(fields.largeLink)"
      >
        <div class="absolute inset-0">
          <VoixMedia :field="fields.largeMedia" class="object-cover w-full h-full" />
        </div>
        <div class="absolute bottom-0 w-full left-0 p-6 bg-black bg-opacity-70">
          <h3 class="font-light uppercase text-4xl">
            {{ fields.largeTitle.value }}
          </h3>
          <VoixLink :field="fields.largeLink" class="text-white hover:underline" />
        </div>
      </div>

      <div class="lg:col-span-4 space-y-12">
        <div
          class="relative aspect-[4/3]" :class="{
            'cursor-pointer': fields.topLink.enabled }
          " @click="goToLink(fields.topLink)"
        >
          <div class="absolute inset-0">
            <VoixMedia :field="fields.topMedia" class="object-cover w-full h-full" />
          </div>
          <div class="absolute bottom-0 left-0 w-full p-6 lg:text-right bg-black bg-opacity-70">
            <h3 class="font-light uppercase text-3xl text-white">
              {{ fields.topTitle.value }}
            </h3>
            <VoixLink :field="fields.topLink" class="text-white text-sm hover:underline" />
          </div>
        </div>
        <div
          class="relative aspect-[4/3]" :class="{
            'cursor-pointer': fields.bottomLink.enabled }
          " @click="goToLink(fields.bottomLink)"
        >
          <div class="absolute inset-0">
            <VoixMedia :field="fields.bottomMedia" class="object-cover w-full h-full" />
          </div>
          <div class="absolute bottom-0 left-0 w-full p-6 lg:text-right bg-black bg-opacity-70">
            <h3 class="font-light uppercase text-3xl text-white">
              {{ fields.bottomTitle.value }}
            </h3>
            <VoixLink :field="fields.bottomLink" class="text-white text-sm hover:underline" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
